import { initSetWidthToButtons } from '../utils/buttons';
import { throttle } from '../utils/rate-limiter';

const initBlock = async( $block ) => {
	const { initDefaultModals } = await import( './../modules/modals' );
	const { initAnimations } = await import( './../modules/animations-on-scroll' );

	const loadMoreVacBtn = $block.find( '.js-view-more-vacancies' );
	const vacanciesContainer = $block.find( '.section-jobs__posts' );
	const $filterCategory = $block.find( '.js-filter-category' );
	const $filterCheckboxes = $( '.section-jobs__filters-checkbox input[type="checkbox"]' );
	const $clearFiltersBtn = $block.find( '.section-jobs__filters-clear' );
	const $filterCount = $block.find( '#filterCount' );

	let isFiltersAnimationRunning = false;

	loadMoreVacBtn.on( 'click', ( e ) => {
		e.preventDefault();
		loadMoreVacancies();
	} );

	initSetWidthToButtons( loadMoreVacBtn );

	function loadMoreVacancies( customRequestUrl = false, animatePosts = false ) {
		const ANIMATION_DURATION = 600;

		let requestUrl = loadMoreVacBtn.attr( 'href' );
		let replacePosts = false;
		let label = loadMoreVacBtn.find( '.btn__label' );
		let labelDefault = loadMoreVacBtn.attr( 'data-label-default' );
		let labelLoading = loadMoreVacBtn.attr( 'data-label-loading' );

		vacanciesContainer.addClass( 'is-loading' );
		loadMoreVacBtn.addClass( 'is-loading' );
		label.html( labelLoading );

		if ( customRequestUrl ) {
			requestUrl = customRequestUrl;
			replacePosts = true;
		}

		$.ajax( {
			url: requestUrl,
			type: 'GET',
			beforeSend: () => {
				if ( animatePosts ) {
					vacanciesContainer.slideUp( ANIMATION_DURATION );
				}
			},
			success: ( response ) => {
				let html = $( response ).find( '.section-jobs__posts' ).html();

				if ( replacePosts ) {
					vacanciesContainer.html( html );
				} else {
					vacanciesContainer.append( html );
				}

				history.pushState( {}, '', requestUrl );

				let $newButton = $( response ).find( '.js-view-more-vacancies' );

				if ( !$newButton.length || $newButton.hasClass( 'button--hidden' ) ) {
					loadMoreVacBtn.addClass( 'button--hidden' );
				} else {
					loadMoreVacBtn.attr( 'href', $newButton.attr( 'href' ) );
					loadMoreVacBtn.removeClass( 'button--hidden' );
				}

				vacanciesContainer.removeClass( 'is-loading' );
				loadMoreVacBtn.removeClass( 'is-loading' );
				label.html( labelDefault );

				initDefaultModals();
				initAnimations();
			},
			complete: () => {
				vacanciesContainer.slideDown( ANIMATION_DURATION );
			}
		} );
	}

	/**
	 * Activate the filter's content.
	 *
	 * @param {jQuery} $target
	 * @param {void}
	 */
	const activateFilterContent = ( $content ) => {
		$content.slideToggle().toggleClass( 'is-active' );
		isFiltersAnimationRunning = false;
	};

	$filterCategory.on( 'click', function( event ) {
		event.preventDefault();
	} );

	$filterCategory.on( 'click', throttle( function() {
		if ( isFiltersAnimationRunning ) {
			return;
		}

		isFiltersAnimationRunning = true;

		const $trigger = $( this );
		const $target = $( $trigger.attr( 'href' ) );
		const $targetSiblings = $target.siblings();
		const targetHasActiveSiblings = $targetSiblings.hasClass( 'is-active' );

		$trigger
			.toggleClass( 'is-active' )
			.parent()
			.siblings()
			.find( '.js-filter-category' )
			.removeClass( 'is-active' );

		$targetSiblings
			.slideUp()
			.removeClass( 'is-active' );

		if ( targetHasActiveSiblings ) {
			setTimeout( () => {
				activateFilterContent( $target );
			}, 400 );
		} else {
			activateFilterContent( $target );
		}
	}, 400 ) );

	$filterCheckboxes.on( 'change', function() {
		updateFilterCount();
		applyFilters();
	} );

	function updateFilterCount() {
		const checkedCount = $filterCheckboxes.filter( ':checked' ).length;

		$filterCount.text( checkedCount );
		$clearFiltersBtn.parent().slideToggle( checkedCount > 0 );
	}

	$clearFiltersBtn.on( 'click', function( e ) {
		e.preventDefault();
		$filterCheckboxes.prop( 'checked', false );
		updateFilterCount();
		applyFilters( true );
	} );

	function applyFilters( clearAll = false ) {
		const params = new URLSearchParams( window.location.search );
		const separator = ',';
		let hasCheckedFilters = false;

		const currentUrl = new URL( window.location.href );
		currentUrl.pathname = currentUrl.pathname.replace( /\/page\/\d+/, '' );

		if ( clearAll ) {
			$( '.section-jobs__filters-group input[type="checkbox"]' ).each( function() {
				const taxonomyName = $( this ).closest( '.section-jobs__filters-group' ).attr( 'id' );
				params.delete( taxonomyName );
			} );
		} else {
			$( '.section-jobs__filters-group' ).each( function() {
				const taxonomyName = $( this ).attr( 'id' );
				params.delete( taxonomyName );

				const selectedTerms = $( this ).find( 'input[type="checkbox"]:checked' )
					.map( function() {
						return $( this ).val();
					} ).get().join( separator );

				if ( selectedTerms ) {
					params.append( taxonomyName, selectedTerms );
					hasCheckedFilters = true;
				}
			} );
		}

		if ( !hasCheckedFilters ) {
			params.delete( 'taxonomy' );
		}

		currentUrl.search = params.toString();

		history.pushState( null, '', currentUrl.href );

		loadMoreVacancies( currentUrl.href, true );
	}

	updateFilterCount();
};

app.loadBlock( initBlock, 'job-opportunities', '.js-section-jobs' );
