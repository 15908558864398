/**
 * Creates a throttled function that only invokes the provided function at most once per
 * every 'wait' milliseconds.
 *
 * @param {Function} func The function to throttle.
 * @param {number} wait The number of milliseconds to throttle invocations to.
 * @return {Function} Returns the new throttled function.
 */
export const throttle = ( func, wait ) => {
	let lastRun = 0;

	return function( ...args ) {
		const now = Date.now();

		if ( now - lastRun >= wait ) {
			func.apply( this, args );
			lastRun = now;
		}
	};
};

/**
 * Creates a debounced function that delays invoking the provided function until after
 * 'wait' milliseconds have elapsed since the last time it was invoked.
 *
 * @param {Function} func The function to debounce.
 * @param {number} wait The number of milliseconds to delay.
 * @return {Function} Returns the new debounced function.
 */
export const debounce = ( func, wait ) => {
	let timeout;

	return function( ...args ) {
		const later = () => {
			timeout = null;
			func.apply( this, args );
		};

		clearTimeout( timeout );
		timeout = setTimeout( later, wait );
	};
};
